import React, { useContext, useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import qs from 'qs'
import { Context } from 'next'
import getConfig from 'next/config'
import Tooltip from '@zcool/tooltip'
import { palette } from '@zcool/theme'
import Flex from '@zcool/flex'
import { PLUS_HIDE_HAF_TYPE } from 'shared/symbols'
import { GlobalContext } from 'shared/context'
import { getLoginSettings, getThirdLoginList } from 'api/auth'
import { isSafeUrl, transformPostmessage } from 'helpers/util'
import { LoginMessageType } from 'helpers/enums'
import LoginWarningModal from 'components/modals/time-warning-modal/login-expired-modal'

const { publicRuntimeConfig } = getConfig()

const LoginContainer = styled.div<{ bgImg: string }>`
  width: 100%;
  color: white;
  height: 100vh;
  overflow-y: auto;
  min-width: 900px;
  background-image: url(${({ bgImg }) => bgImg});
  background-repeat: no-repeat;
  background-color: #fff;
  background-size: cover;

  .header {
    padding-top: 30px;
    max-width: 1200px;

    nav {
      height: 28px;
      display: flex;
      align-items: center;

      .logo {
        width: auto;
        min-height: 20px;
        max-height: 28px;
        float: left;
        margin-right: 10px;
        padding-right: 10px;
        border-right: 1px solid ${({ theme }) => theme.customize.header_title_color};
      }

      span {
        font-size: 16px;
        line-height: 28px;
        color: ${({ theme }) => theme.customize.header_title_color};
      }
    }

    .title {
      font-size: 18px;
      padding: 20px 0 30px;
      text-align: center;

      img {
        min-height: 30px;
        max-height: 72px;
        margin-bottom: 20px;
      }

      .slogan {
        color: ${({ theme }) => theme.customize.header_slogan_color};
      }
    }
  }

  .main {
    width: 756px;
    height: 480px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    background-color: #fff;

    .third-login-list {
      position: absolute;
      width: 400px;
      height: 40px;
      bottom: 40px;
      right: 0;

      .item-group {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      .item {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        border: 1px solid #e0e0e0;
        overflow: hidden;

        :hover {
          border-color: ${palette.primary};
        }

        & + .item {
          margin-left: 24px;
        }

        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .footer {
    text-align: center;
    font-size: 12px;
    margin-bottom: 40px;

    p:first-child {
      font-size: 14px;
      margin-top: 20px;
    }

    p:last-child {
      margin-top: 8px;
    }
  }

  .header,
  .main {
    margin: auto;
  }
`
interface Props {
  settings?: any
  domain: string
  query?: any
  thirdLoginList: { name: string; icon_url: string; url: string; desc: string }[]
}

const ThirdLoginIcon = ({
  thirdLoginList,
}: {
  thirdLoginList: { name: string; icon_url: string; url: string; desc: string }[]
}) => {
  return (
    !!thirdLoginList?.length && (
      <Flex className="third-login-list">
        <Flex className="item-group">
          {thirdLoginList.map((item) => (
            <Flex className="item" key={item.name} halign="center" valign="center">
              <Tooltip title={item.desc} placement="top" hasArrow={true}>
                <a href={item.url}>
                  <img src={item.icon_url} alt="" />
                </a>
              </Tooltip>
            </Flex>
          ))}
        </Flex>
      </Flex>
    )
  )
}

export default function Login(props: Props) {
  const { query, thirdLoginList } = props
  const hasSettings = JSON.stringify(props.settings) !== '[]'
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [showLoginWarningModal, setShowLoginWarningModal] = useState(false)
  let { settings } = useContext(GlobalContext)
  if (hasSettings) {
    settings = props.settings
  }
  // const { login_background_image, login_left_top_logo, login_middle_logo, login_name, login_slogan } = settings
  const login_background_image = settings.login_background_image || settings.background_image
  const login_left_top_logo = settings.login_left_top_logo || settings.left_top_logo
  const login_middle_logo = settings.login_middle_logo || settings.middle_logo
  const login_name = settings.login_name || settings.name
  const login_slogan = settings.login_slogan || settings.slogan

  const onSuccess = async (e: any) => {
    const data = transformPostmessage(e)
    if (data?.type === LoginMessageType.ReadyLoginSuccess) {
      return window.location.replace('/')
    }
    if (data?.type === LoginMessageType.LoginSuccess) {
      window.location.replace(
        `/passport?${qs.stringify({
          TOKEN: data.data,
          callback: isSafeUrl(query.callback) ? query.callback : '/',
        })}`
      )
    }

    if (data?.type === LoginMessageType.LoginExpired) {
      setShowLoginWarningModal(true)
    }
  }

  useEffect(() => {
    window.addEventListener('message', onSuccess)

    return () => {
      window.removeEventListener('message', onSuccess)
    }
  }, [])

  return (
    <LoginContainer bgImg={login_background_image}>
      <header className="header">
        <nav>
          <a href={props.domain}>
            <img className="logo" src={login_left_top_logo} />
          </a>
          <span>{login_name}</span>
        </nav>
        <h2 className="title">
          <a href={props.domain}>
            <img src={login_middle_logo} />
          </a>
          <br />
          <span className="slogan">{login_slogan}</span>
        </h2>
      </header>
      <main className="main" style={{ width: hasSettings ? 400 : 756 }}>
        <iframe
          src={`${publicRuntimeConfig.PASSPORT_LOGIN_URL}${hasSettings ? '&concise=1' : ''}`}
          ref={iframeRef}
          // src={`${`https://local.hellorf.com:4000/?appid=10021&status=1`}${hasSettings ? '&concise=1' : ''}`}
          frameBorder="0"
          width={'100%'}
          height={480}
        />
        <ThirdLoginIcon thirdLoginList={thirdLoginList} />
      </main>
      <footer className="footer">
        {!hasSettings && (
          <p>
            <a href={`https://www.hellorf.com/image/show/${319182950}`} target="_blank">
              图片ID：319182950
            </a>
            &nbsp; 版权：NextMars
          </p>
        )}
        <p style={{ marginTop: 40 }}>京ICP备11017824号-4 / 京ICP证130164号 北京市公安局朝阳分局备案编号:110105000501</p>
        <p>
          {`Copyright © 2006-${new Date().getFullYear()} ZCOOL`}
          <span>中文</span> <span>English</span>
        </p>
      </footer>
      <LoginWarningModal
        open={showLoginWarningModal}
        iframeRef={iframeRef}
        setShowLoginWarningModal={setShowLoginWarningModal}
      />
    </LoginContainer>
  )
}

Login.$$type = PLUS_HIDE_HAF_TYPE

Login.getInitialProps = async function ({ query, domain }: Context) {
  const [{ result, data }, { data: thirdLoginList }] = await Promise.all([
    getLoginSettings(domain),
    getThirdLoginList({ url: domain }),
  ])

  return {
    result,
    settings: data,
    domain,
    query,
    thirdLoginList: thirdLoginList || [],
  }
}

// export const getServerSideProps = async function ({ query, req }: Context) {
//   const domain = `https://${(req as any).get('host')}`
//   const [{ result, data }, { data: thirdLoginList }] = await Promise.all([
//     getLoginSettings(domain),
//     getThirdLoginList({ url: domain }),
//   ])

//   return {
//     props: {
//       result,
//       settings: data,
//       domain,
//       query,
//       thirdLoginList: thirdLoginList || [],
//     },
//   }
// }
