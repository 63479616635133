import React from 'react'
import { Modal, Button } from 'components/antd'
import styled from 'styled-components'
import { ConfigProvider } from 'antd'

const TimeModal = styled(Modal)`
  .ant-modal-title {
    color: #1c1d1f;
    font-family: 'PingFang SC';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    margin-bottom: 24px;
  }

  .modal-content {
    & > p {
      color: #333;
      font-family: 'PingFang SC';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 40px;
    }

    & > :first-child {
      margin-bottom: 12px;
    }

    & > div {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      gap: 16px;
      align-self: stretch;

      & > button {
        flex: 1;
        font-weight: 500;
      }
    }
  }

  .ant-modal-content {
    border-radius: 8px;
  }
`

interface LoginWarningModalProps {
  time?: number
  onClose?: () => void
  open: boolean
  iframeRef?: React.RefObject<HTMLIFrameElement>
  setShowLoginWarningModal: (value: boolean) => void
}

const LoginWarningModal: React.FC<LoginWarningModalProps> = ({
  onClose: propOnClose,
  open = false,
  iframeRef,
  setShowLoginWarningModal,
}) => {
  const handleClose = () => {
    setShowLoginWarningModal(false)
    propOnClose?.()
  }

  const handleModify = () => {
    // 发送消息到 iframe
    if (iframeRef?.current?.contentWindow) {
      iframeRef.current.contentWindow.postMessage({ type: 'login_warning_closed' }, '*')
      handleClose()
    }
  }

  return open ? (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            primaryColor: '#fff',
            colorPrimary: '#F74640',
            colorPrimaryHover: '#F74640dd',
            defaultActiveColor: '#F74640',
            colorPrimaryActive: '#c23322',
            defaultColor: '#1C1D1F',
            defaultBorderColor: '#DCDCDC',
            fontSize: 14,
            fontWeight: 500,
          },
        },
      }}
    >
      <TimeModal
        open={open}
        onCancel={() => {
          handleClose()
        }}
        title="您的密码已过期，请尽快修改"
        footer={null}
        width={480}
      >
        <div className="modal-content">
          <p>贵公司已启用90天“定期修改密码”功能，您的密码已过期，请尽快修改密码以继续正常登录。</p>
          <p>您可以点击下方按钮，修改密码</p>
          <div>
            <Button onClick={handleClose}>关闭</Button>
            <Button type="primary" onClick={handleModify}>
              修改密码
            </Button>
          </div>
        </div>
      </TimeModal>
    </ConfigProvider>
  ) : null
}

export default LoginWarningModal
